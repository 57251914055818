import React, { Fragment } from 'react'
import Helmet from 'react-helmet'

export default function Pagina (props) {
  const { pagina } = props
  console.log(props)
  console.log(pagina.node_locale.split('-')[0])
  return (
    <Fragment>
      <Helmet htmlAttributes={{

      }}>
        <meta charSet="utf-8" />
        <html lang={`${pagina.node_locale.split('-')[0]}`} />
        <title>{`${pagina.metaTitle}`}</title>
        <meta name="title" content={`${pagina.metaTitle}`} />
        <meta name="description" content={pagina.metaDescription} />
        <meta name="keywords" content={pagina.tags.join(', ')} />
        <meta name="author" content="Default" />
        <meta name="robots" content="index, follow" />
        <meta property="og:title" content={`${pagina.metaTitle}`} />
        <meta property="og:type" content="article" />
        <meta property="og:url" content={`https://www.grupolwart.com.br${pagina.path}`} />
        {/* <meta
          property="og:image"
          content={`https://www.default.com.br${pagina.card.localFile.publicURL}`}
        /> */}
        <meta property="og:description" content={pagina.metaDescription} />
      </Helmet>
    </Fragment>
  )
}
